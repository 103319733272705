import { act } from "@testing-library/react";
import { AxiosResponse } from "axios";
import { put, takeLatest } from "redux-saga/effects";
import AxiosInstance from "../../services/interceptor";
import { fetchBGPDetailsFailure, fetchBGPDetailsSuccess, fetchBGPPrefixListFailure, fetchBGPPrefixListSuccess, fetchInterfaceDetailsFailure, fetchInterfaceDetailsSuccess, fetchSummaryTabDetailsFailure, fetchSummaryTabDetailsSuccess, fetchVlansDetailsFailure, fetchVlansDetailsSuccess } from "./action";
import { FetchBGPDetails, FetchBGPPrefixList, FetchInterfaceDetails, FetchSummaryTabDetails, FetchVlansDetails, IFetchBGPDetailsAction, IFetchBGPPrefixListAction, IFetchInterfaceDetailsAction, IFetchSummaryTabDetailsAction, IFetchVlansDetailsAction } from "./actionTypes";
import { IBGPDetailsResponse, IBGPPrefixListResponse } from "./types/bgpTab.types";
import { IVlansDetailsResponse } from "./types/nmsDeviceDetails.types";
import { IInterfaceDetailsResponse } from "./types/nmsInterfaceTab.types";
import { ISummaryTabDetailsResponse } from "./types/nmsSummaryTab.types";


//****VlanTab****/
export function* loadVlansDetails(action: Readonly<IFetchVlansDetailsAction>): Generator {
  try {
    const rawData = yield AxiosInstance.post<IVlansDetailsResponse>(`${window.location.origin}/api/devdetailsvlanlist`,
      {
        top: action.request.top,
        skip: action.request.skip,
        filters: action.request.filters,
        deviceId: action.request.deviceId,
        serviceTreeId: action.request.serviceTreeId
      });

    const result = rawData as AxiosResponse<IVlansDetailsResponse>;
    yield put(fetchVlansDetailsSuccess(result.data));

  }
  catch (error) {
    yield put(fetchVlansDetailsFailure(error as Error));
  }
}


//****InterfaceTab****/
export function* loadInterfaceDetails(action: Readonly<IFetchInterfaceDetailsAction>): Generator {
  try {
    const rawData = yield AxiosInstance.post<IInterfaceDetailsResponse>(`${window.location.origin}/api/devdetailsinterfacelist`,
      {
        top: action.request.top,
        skip: action.request.skip,
        filters: action.request.filters,
        deviceId: action.request.deviceId,
        serviceTreeId: action.request.serviceTreeId
      });

    const result = rawData as AxiosResponse<IInterfaceDetailsResponse>;
    yield put(fetchInterfaceDetailsSuccess(result.data));

  }
  catch (error) {
    yield put(fetchInterfaceDetailsFailure(error as Error));
  }
}


//****SummaryTab****/
export function* loadSummaryTabDetails(action: Readonly<IFetchSummaryTabDetailsAction>): Generator {
  try {
    const rawData = yield AxiosInstance.post<ISummaryTabDetailsResponse>(`${window.location.origin}/api/devdetailssummary`,
      {
        deviceId: action.request.deviceId,
        serviceTreeId: action.request.serviceTreeId
      });

    const result = rawData as AxiosResponse<ISummaryTabDetailsResponse>;
    yield put(fetchSummaryTabDetailsSuccess(result.data));
  }
  catch (error) {
    yield put(fetchSummaryTabDetailsFailure(error as Error));
  }
}


//****BGP Tab****/
export function* loadBGPDetails(action: Readonly<IFetchBGPDetailsAction>): Generator {
  try {
    const rawData = yield AxiosInstance.post<IBGPDetailsResponse>(`${window.location.origin}/api/bgpdetail`,
      {
        deviceId: action.request.deviceId,
        serviceTreeId: action.request.serviceTreeId
      });

    const result = rawData as AxiosResponse<IBGPDetailsResponse[]>;
    yield put(fetchBGPDetailsSuccess(result.data));
  }
  catch (error) {
    yield put(fetchBGPDetailsFailure(error as Error));
  }
}


//****BGP Prefix List****/
export function* loadBGPPrefixList(action: Readonly<IFetchBGPPrefixListAction>): Generator {
  try {
    const rawData = yield AxiosInstance.post<IBGPPrefixListResponse>(`${window.location.origin}/api/bgpprefix`,
      {
        prefixRequest: action.request.prefixRequest,
        serviceTreeId: action.request.serviceTreeId    
      });

    const result = rawData as AxiosResponse<IBGPPrefixListResponse>;
    yield put(fetchBGPPrefixListSuccess(result.data));
      console.log(result)
  }
  catch (error) {
    yield put(fetchBGPPrefixListFailure(error as Error));
    console.log(error)
  }
}

//MUST validate this
export function* devicedetailsSagas(): Generator {
  yield takeLatest(FetchVlansDetails, loadVlansDetails);
  yield takeLatest(FetchInterfaceDetails, loadInterfaceDetails);
  yield takeLatest(FetchSummaryTabDetails, loadSummaryTabDetails);
  yield takeLatest(FetchBGPDetails, loadBGPDetails);
  yield takeLatest(FetchBGPPrefixList, loadBGPPrefixList);
}